export const products = [
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "6.4 inch",
    desc:
      "This 6.4-inch (diagonal) XGA display delivers a 800:1 contrast ratio and 350 nits of brightness. It is ideal for use in test and measurement applications where a high-resolution display is required, medical applications, and a number of additional industrial applications. The panel features a dot pitch of 0.12675 and an LVDS interface.",
    PN: "LQ064X3LW02",
    BRIGHTNESS: "350 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "800:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-20°C to +70°C",
    "STORAGE TEMP": "-25°C to +70°C",
    "OUTLINE DIMENSIONS": "153.4 x 122.0 x 9.9 mm",
    "PIXEL FORMAT": "1024 x 768",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "80°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ064X3LW02-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/LQ064X3LW02_LD29X01A_SEC_D_20210408.pdf"),
        name: "LQ064X3LW02_LD29X01A_SEC_D_20210408",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "7.0 inch",
    desc:
      "This 15:9 aspect WVGA module features an LVDS interface, fast response time of 16 ms, an LED backlight (with built-in driver), and brightness and contrast ratings of 350 nits and 500:1, respectively. The module also incorporates anti-glare and 3H hard coatings.",
    PN: "LQ070Y3LG05",
    BRIGHTNESS: "380 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "500:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-10°C to +70°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "165.0  x  104.0  x  7.41  mm",
    "PIXEL FORMAT": "800 x 480",
    "RESPONSE TIME": "16 ms",
    "VIEWING ANGLE 12 O'CLOCK": "50°",
    "VIEWING ANGLE 6 O'CLOCK": "70°",
    "VIEWING ANGLE SIDE TO SIDE": "140°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ070Y3LG05-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ070Y3LG05-1-20-15.pdf"),
        name: "Sharp-LCD-Specification-LQ070Y3LG05-1-20-15",
      },
    ],
  },

  {
    Applications: "",
    desc:
      "This WVGA (800 × 480) automotive-grade display features 650 nits of brightness and 1800:1 contrast ratio, right out of the box, for use in high-performance industrial products. The display is perfect for viewability in the toughest environments, and high ambient light applications. The module also features an extended temperature range and a 50,000-hour LED backlight.",
    PN: "LQ080Y3LW01A",
    "DIAGONAL SIZE": "8.0 inch",
    BRIGHTNESS: "650 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "1800:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-30°C to +85°C",
    "STORAGE TEMP": "-40°C to +90°C",
    "OUTLINE DIMENSIONS": "191.8 x 120.7 x 7.25 mm",
    "PIXEL FORMAT": "800 x 480",
    "RESPONSE TIME": "40 ms",
    "VIEWING ANGLE 12 O'CLOCK": "80°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ080Y3LW01A-Mechanical-Drawing.jpeg")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/SPEC_SEC-D_LQ080Y3LW01A_LD2022102A_220127.pdf"),
        name: "SPEC_SEC-D_LQ080Y3LW01A_LD2022102A_220127",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "8.4 inch",
    desc:
      "This module features a 50,000-hr rated backlight, extended operating and storage temperature ranges, plus shock and vibration resistance.",
    PN: "LQ084S3LG03",
    BRIGHTNESS: "330 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "450:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-30°C to +75°C",
    "STORAGE TEMP": "-30°C to +75°C",
    "OUTLINE DIMENSIONS": "199.5  x  154.0  x  11.6 mm",
    "PIXEL FORMAT": "800 x 600",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "60°",
    "VIEWING ANGLE 6 O'CLOCK": "55°",
    "VIEWING ANGLE SIDE TO SIDE": "130°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ084S3LG03-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ084S3LG03-6-1-11.pdf"),
        name: "Sharp-LCD-Specification-LQ084S3LG03-6-1-11",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "8.4 inch",
    desc:
      "This 8.4-inch (diagonal) SVGA display delivers a 600:1 contrast ratio and 400 nits of brightness. It is ideal for use in test and measurement applications where a high-resolution display is required, medical applications, and a number of additional industrial applications. The display has an LVDS interface.",
    PN: "LQ084S3LG11",
    BRIGHTNESS: "400 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "600:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "200.0 x 152.0 x 11.6 mm",
    "PIXEL FORMAT": "800 x 600",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "80°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ084S3LG11-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ084S3LG11-10-22-18.pdf"),
        name: "Sharp-LCD-Specification-LQ084S3LG11-10-22-18",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "10.4 inch",
    desc:
      "This high-performance 10.4-inch (diagonal) SVGA TFT display is optimized to perform in extreme industrial environments such as agriculture, marine navigation, and others. It delivers 1100 nits of brightness out of the box for superb viewability in high ambient light conditions and includes a built-in LED backlight driver. The display is readable while wearing polarized sunglasses.",
    PN: "LQ104S1LG75",
    BRIGHTNESS: "1100 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "400:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "262,144",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-40°C to 85°C",
    "OUTLINE DIMENSIONS": "242 x 185 x 13.4 mm",
    "PIXEL FORMAT": "800 x 600",
    "RESPONSE TIME": "25 ms",
    "VIEWING ANGLE 12 O'CLOCK": "60°",
    "VIEWING ANGLE 6 O'CLOCK": "50°",
    "VIEWING ANGLE SIDE TO SIDE": "120°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ104S1LG75-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ104S1LG75-2-3-17.pdf"),
        name: "Sharp-LCD-Specification-LQ104S1LG75-2-3-17",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "10.4 inch",
    desc:
      "This 4:3 aspect SVGA Module features a 50,000-hr LED backlight (built-in driver), LVDS interface, extended temperature capability, shock and vibration resistance, and 3H hard coating.",
    PN: "LQ104S1LG81",
    BRIGHTNESS: "420 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "600:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "262,144",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "246.5  x  179.3  x  12.5 mm",
    "PIXEL FORMAT": "800 x 600",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "50°",
    "VIEWING ANGLE 6 O'CLOCK": "60°",
    "VIEWING ANGLE SIDE TO SIDE": "140°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ104S1LG81-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ104S1LG81-6-20-12.pdf"),
        name: "Sharp-LCD-Specification-LQ104S1LG81-6-20-12",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "10.4 inch",
    desc:
      "This module features a 50,000-hr rated backlight with a built-in driver. An LVDS interface version of this module is also available, see LQ104V1LG81. For Specifications, see your local Sharp Representative.",
    PN: "LQ104V1DG81",
    BRIGHTNESS: "450 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "800:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "262,144",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "246.5  x  179.3  x  12.5 mm",
    "PIXEL FORMAT": "640 x 480",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "65°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ104V1DG81-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ104V1DG81-9-1-11.pdf"),
        name: "Sharp-LCD-Specification-LQ104V1DG81-9-1-11",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "10.4 inch",
    desc:
      "This module with high contrast features a 50,000-hr rated backlight, extended temperature, shock, and vibration ratings; built-in backlight driver. An LVDS interface version is also available, see: LQ104V1LG81. For Specifications, see your local Sharp Representative.",
    PN: "LQ104V1LG81",
    BRIGHTNESS: "450 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "800:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "262,144",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "246.5  x  179.4  x  12.5 mm",
    "PIXEL FORMAT": "640 x 480",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "65°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ104V1LG81-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ104V1LG81-8-30-11.pdf"),
        name: "Sharp-LCD-Specification-LQ104V1LG81-8-30-11",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "12.1 inch",
    desc:
      "This 16:10 aspect ratio module features 50,000-hr backlight and built-in backlight driver circuitry.",
    PN: "LQ121K1LG52",
    BRIGHTNESS: "400 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "800:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "278.0  x  184.0  x  8.6 mm",
    "PIXEL FORMAT": "1280 x 800",
    "RESPONSE TIME": "30 ms",
    "VIEWING ANGLE 12 O'CLOCK": "65°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ121K1LG52-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ121K1LG52-12-3-10.pdf"),
        name: "Sharp-LCD-Specification-LQ121K1LG52-12-3-10",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "12.1 inch",
    desc:
      "This 16:10 aspect ratio WXGA LCD module features high brightness and contrast, 70% NTSC ratio, extended temperature operations, a 50,000 hr. backlight, and built-in LED driver circuitry.",
    PN: "LQ121K1LG58",
    BRIGHTNESS: "700 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "800:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-30°C to +80°C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "278.0 x 184.0 x 8.6 mm",
    "PIXEL FORMAT": "1280 x 800",
    "RESPONSE TIME": "30 ms",
    "VIEWING ANGLE 12 O'CLOCK": "80°",
    "VIEWING ANGLE 6 O'CLOCK": "65°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ121K1LG58-Mechanical-Drawing-clean.png")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ121K1LG58-12-6-16.pdf"),
        name: "Sharp-LCD-Specification-LQ121K1LG58-12-6-16",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "12.1 inch",
    desc:
      "This 12.1-inch Sharp SVGA TFT LCD features an LVDS interface, 450 nits of brightness, and a high 800:1 contrast ratio for excellent viewability in tough ambient lighting conditions. The module includes a built-in LED Driver for ease of design. ",
    PN: "LQ121S1LG88",
    BRIGHTNESS: "450 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "800:1",
    "DISPLAY MODE": "Normally White",
    "NUMBER OF COLORS": "262,144",
    "OPERATING TEMP": "-15 C to +70 C",
    "STORAGE TEMP": "-30°C to +80°C",
    "OUTLINE DIMENSIONS": "276.0  x  209.0  x  9.1 mm",
    "PIXEL FORMAT": "800 x 600",
    "RESPONSE TIME": "30 ms",
    "VIEWING ANGLE 12 O'CLOCK": "60°",
    "VIEWING ANGLE 6 O'CLOCK": "80°",
    "VIEWING ANGLE SIDE TO SIDE": "160°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ121S1LG88-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ121S1LG88 6-26-12.pdf"),
        name: "Sharp-LCD-Specification-LQ121S1LG88 6-26-12",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "15.0 inch",
    desc:
      "This Normally Black 4:3 aspect XGA module, features extremely high contrast, extremely wide symmetrical viewing cone, 70% NTSC colorimetry, LVDS interface, and a white LED backlight which carries a 50,000 hour lifetime rating. It also has an anti-glare and 3H hard coating.",
    PN: "LQ150X1LX95",
    BRIGHTNESS: "400 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "1500:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-20°C to +70°C",
    "STORAGE TEMP": "-25°C to +70°C",
    "OUTLINE DIMENSIONS": "326.5  x  253.5  x  9.6 mm",
    "PIXEL FORMAT": "1024 x 768",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "85°",
    "VIEWING ANGLE 6 O'CLOCK": "85°",
    "VIEWING ANGLE SIDE TO SIDE": "170°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ150X1LX95-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ150X1LX95-2-20-14.pdf"),
        name: "Sharp-LCD-Specification-LQ150X1LX95-2-20-14",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "15.0 inch",
    desc:
      "This Normally Black 4:3 aspect XGA module features extremely high contrast, extremely wide symmetrical viewing cone, 70% NTSC colorimetry, LVDS interface, and a white LED backlight which carries a 50,000 hour lifetime rating. It also has an anti-glare and 3H hard coating.",
    PN: "LQ150X1LX96",
    BRIGHTNESS: "500 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "1500:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-20°C to +70°C",
    "STORAGE TEMP": "-25°C to +70°C",
    "OUTLINE DIMENSIONS": "326.5  x  253.5  x  9.6 mm",
    "PIXEL FORMAT": "1024 x 768",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "85°",
    "VIEWING ANGLE 6 O'CLOCK": "85°",
    "VIEWING ANGLE SIDE TO SIDE": "170°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ150X1LX96-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ150X1LX96-7-13-16.pdf"),
        name: "Sharp-LCD-Specification-LQ150X1LX96-7-13-16",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "15.6 inch",
    desc:
      "This 15.6-inch display combines a bright LED backlight with color filtering to render 76% NTSC. This results in bright, vivid images that are ideal for multi-media applications. The LCD also features wide viewing angles.",
    PN: "LQ156T3LW03",
    BRIGHTNESS: "400 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "1000:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-20°C to +70°C",
    "STORAGE TEMP": "-25°C to +70°C",
    "OUTLINE DIMENSIONS": "363.8 × 215.9 × 10.8 mm",
    "PIXEL FORMAT": "1366 x 768",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "85°",
    "VIEWING ANGLE 6 O'CLOCK": "85°",
    "VIEWING ANGLE SIDE TO SIDE": "170°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ156T3LW03-Mechanical-Drawing.gif")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ156T3LW03-8-27-15.pdf"),
        name: "Sharp-LCD-Specification-LQ156T3LW03-8-27-15",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "19.0 inch",
    desc:
      "This Normally Black 5:4 aspect SXGA module, features extremely high contrast, extremely wide symmetrical viewing cone, an LVDS interface, and a white LED backlight which carries a 70,000 hour lifetime rating. It also has an anti-glare and 3H hard coating.",
    PN: "LQ190E1LX75T",
    BRIGHTNESS: "350 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "1500:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-20°C to +70°C",
    "STORAGE TEMP": "-25°C to +60°C",
    "OUTLINE DIMENSIONS": "396.0  x  323.6  x  11.5 mm",
    "PIXEL FORMAT": "1280 x 1024",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "85°",
    "VIEWING ANGLE 6 O'CLOCK": "85°",
    "VIEWING ANGLE SIDE TO SIDE": "170°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ190E1LX75T-Mechanical-Drawing-clean.png")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ190E1LX75T-6-23-15.pdf"),
        name: "Sharp-LCD-Specification-LQ190E1LX75T-6-23-15",
      },
    ],
  },
  {
    Applications:
      "Wearable Technology,Mobile Phones,Remote Controls,Performance Tracking Devices,Parking Meters,Smart Utility Meters,Medical Monitoring Devices, Wristwatches,Fish Finders,White Goods,Retail Tags,Any product with a battery",
    "DIAGONAL SIZE": "19.0 inch",
    desc:
      "This SXGA TFT LDC panel features high contrast of 1500:1 and 470 nits of brightness to render extremely crisp images. It is ideal for viewability in challenging lighting conditions.",
    PN: "LQ190E1LX76",
    BRIGHTNESS: "470 nits",
    "LIFE CYCLE": "Production",
    "CONTRAST RATIO": "1500:1",
    "DISPLAY MODE": "Normally Black",
    "NUMBER OF COLORS": "16.7M",
    "OPERATING TEMP": "-20°C to +70°C",
    "STORAGE TEMP": "-25°C to +75°C",
    "OUTLINE DIMENSIONS": "396.0 × 323.6 × 11.5 mm",
    "PIXEL FORMAT": "1280 x 1024",
    "RESPONSE TIME": "35 ms",
    "VIEWING ANGLE 12 O'CLOCK": "85°",
    "VIEWING ANGLE 6 O'CLOCK": "85°",
    "VIEWING ANGLE SIDE TO SIDE": "170°",
    img: [require("../assets/Industrial LCD Drawings/Sharp-LQ190E1LX76-Mechanical-Drawing-clean.png")],
    DOCUMENTS: [
      {
        link: require("../assets/Documents/Sharp-LCD-Specification-LQ190E1LX76-4-11-18.pdf"),
        name: "Sharp-LCD-Specification-LQ190E1LX76-4-11-18",
      },
    ],
  },
];
